import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import ContentContainer from '../components/UI/ContentContainer';
import PageContent from '../components/UI/PageContent';
import PageHeaderPro from '../components/UI/PageHeaderPro';
import { breakpointLarge } from '../styles/breakpoints';

const PageContentOverlap = styled(PageContent)`
  margin-top: -4.5rem;
  z-index: 98;
  position: relative;
  @media (min-width: 950px) {
    margin-top: -5.8rem;
  }
`;
const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 2.5rem;
`;
const Item = styled.div`
  padding: 1.5rem;
  display: flex;
  @media (min-width: ${breakpointLarge}) {
    padding: 1.7rem;
    max-width: calc(50% - 1rem);
    margin: 0.5rem;
  }
`;
const ItemIcon = styled(Img)`
  width: 50px;
  height:50px;
  @media (min-width: ${breakpointLarge}) {
    width:90px;
    height:90px;
  }
`;
const ContinerIcon = styled.div`
  background-color: ${props => props.theme.gray200};
  border-radius: 1.7rem;
  displey:flex;
  padding: 1.2rem;
  justify-content:center;
  aling-items:center;
  margin-right: 1rem;
  width: 90px;
  height:90px;
  @media (min-width: ${breakpointLarge}) {
    width:150px;
    height:150px;
    padding: 1.8rem;
  }
  
`
const ItemText = styled.div`
  color: ${props => props.theme.gray};
  h3 {
    margin: 0;
    font-size: 1.5rem;
    line-height: 0.9;
    font-weight: 700;
  }
  p {
    margin: 0.75rem 0;
    text-align:start;
  }
  @media (min-width: ${breakpointLarge}) {
    h3 {
      margin: 0;
      font-size: 2rem;
      line-height: 0.9;
      font-weight: 800;
    }
  }
`;

export const PrivilegiosPageTemplate = ({
  image,
  imageMobile,
  imagePosition,
  title,
  description,
  items,
}) => {
  return (
    <section>
      <Cookies/>
      <PageHeaderPro
        image={image}
        imageMobile={imageMobile}
        backgroundPosition={imagePosition}
        title={title}
        description={description}
      />
      <PageContentOverlap>
        <ContentContainer>
          <Items>
            {items.map(item => (
              <Item key={item.title}>
                <ContinerIcon>
                <ItemIcon
                  fluid={item.icon.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'fill',
                   
                  }}
                />
                </ContinerIcon>
                <ItemText>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </ItemText>
              </Item>
            ))}
          </Items>
        </ContentContainer>
      </PageContentOverlap>
    </section>
  );
};

PrivilegiosPageTemplate.propTypes = {
  image: PropTypes.object,
  imagePosition: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageMobile:PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

const PrivilegiosPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PrivilegiosPageTemplate
        image={post.frontmatter.image}
        imagePosition={post.frontmatter.imagePosition}
        imageMobile={post.frontmatter.imageMobile}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        items={post.frontmatter.items}
      />
    </Layout>
  );
};

PrivilegiosPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivilegiosPage;

export const privilegiosPageQuery = graphql`
  query PrivilegiosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2018, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile{
          childImageSharp {
            fluid(maxWidth: 2018, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imagePosition
        title
        description
        items {
          icon {
            childImageSharp {
              fluid(maxWidth: 150, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
        }
      }
    }
  }
`;
